import React, { useEffect, useState } from "react";
import { AxiosInstance, PORTS } from "../../../utils/apiService";
import InputComponent from "../../modules/ReusableComponent/InputComponent";
import commonstyles from "../../../styles/Management_Common.module.css";
import FormErrorText from "../../modules/ProductManagement/FormErrorText";
import styles from "../../../styles/PaymentNew.module.css";
import BlackLeftArrow from "../../../assets/images/black_left_arrow.svg";
import { Select, notification } from "antd";
import bannerimgdelete from "../../../assets/images/Iconly-Bulk-Close Square.svg";
import ButtonComponent from "../../modules/ReusableComponent/ButtonComponent";
import SelectComponent from "../../modules/ReusableComponent/SelectComponent";
import SortableList, { SortableItem } from "react-easy-sort";
import { arrayMove } from "react-movable";
import { useHistory } from "react-router";
import moment from "moment";
import ConfirmationBox from "../../modules/ReusableComponent/ConfirmationBox";
import Compressor from 'compressorjs'; 
import LoadingIndicator from "../../modules/ReusableComponent/LoadingIndicator";




const SpecialOfferCategoryBanners = (props) => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [regionId, setRegionId] = useState("");
  const [selectedSortOrders, setSelectedSortOrders] = useState(new Set());
  const createInitialForms = () => {
    return Array(4).fill(null).map((_,index) => ({  display: 1,
      title: "",
      url:"",
      images: [],
      updatedAt: "",
      sortOrder: index + 1, 
      userName: "",
      errors: { title: '',url:"", display: '', images: '',sortOrder:'' } }))
  }

 

  const [forms, setForms] = useState(createInitialForms());
  const [loading, setLoading] = useState({ details: false, update: false });
  const [confirmClear, setConfirmClear] = useState({ show: false, formIndex: null });
  useEffect(() => {
    const orders = new Set(forms.map(form => form.sortOrder));
    setSelectedSortOrders(orders);
  }, [forms]);
  useEffect(() => {
    getRegionWiseLanding();
    if (!props.moduleName || props.moduleName === undefined) {
      setTimeout(() => {
        history.push("/Content")
      }, 200);
    }
  }, [regionId]); //  regionId as dependency to reload when region changes
  const handleSortOrderChange = (formIndex, newValue) => {
    const newSortOrder = parseInt(newValue);
    
    // ffind if sort order is already used in another form
    const existingFormIndex = forms.findIndex((form, idx) => 
      idx !== formIndex && form.sortOrder === newSortOrder
    );

    setForms(prevForms => {
      const newForms = [...prevForms];
      
      if (existingFormIndex !== -1) {
        // Perform the swap
        const oldSortOrder = prevForms[formIndex].sortOrder;
        
        // Show notification about the swap
        showNotification({
          type: "info",
          message: "Sort Order Swapped",
          description: `Sort order ${newValue} was swapped `
       

          // }`
        });
//         // Update the form that previously had this sort order
        newForms[existingFormIndex] = {
          ...newForms[existingFormIndex],
          sortOrder: oldSortOrder,
          errors: {
            ...newForms[existingFormIndex].errors,
            sortOrder: ""
          }
        };
      }

      // Update the current form with new sort order
      newForms[formIndex] = {
        ...newForms[formIndex],
        sortOrder: newSortOrder,
        errors: {
          ...newForms[formIndex].errors,
          sortOrder: newValue === "" ? "Please select a sort order" : ""
        }
      };

      return newForms;
    });

    // Update selected sort orders
    setSelectedSortOrders(prev => {
      const newSet = new Set();
      forms.forEach((form, idx) => {
        if (idx === formIndex) {
          newSet.add(newSortOrder);
        } else if (idx === existingFormIndex) {
          newSet.add(forms[formIndex].sortOrder);
        } else if (form.sortOrder) {
          newSet.add(form.sortOrder);
        }
      });
      return newSet;
    });
  };
  
  const getSortOptions = () => {
    const options = [
    
  ];
    for (let i = 1; i <= 4; i++) {
      options.push(
        <option key={i} value={i}>
          {i}
        </option>
      );
    }
    return options;
  };
  async function getRegionWiseLanding() {
    setIsLoading(true);
    try {
      const response = await AxiosInstance(PORTS.DASHBOARD_SERVICE, "application/json")
        .get(`/getRegionLanding/${props.state}`);

      if (response.data.status === 200) {
        const regionData = response.data.data;
        // Find data for current regionId
        const currentRegionData = regionData[0]?.region?.find(r => 
          r.regionId == (regionId || null)
        );
          // If no data exists for the region, reset all forms to initial state
          if (!currentRegionData || !currentRegionData.forms) {
            setForms(createInitialForms());
            setSelectedSortOrders(new Set());
            // setForms(Array(5).fill(null).map(() => ({ ...initialFormState })));
            return;
          }

          const updatedSelectedSortOrders = new Set();
        // Map the forms data or use empty forms
        const newForms = Array(4).fill(null).map((_, index) => {
          const formData = currentRegionData?.forms?.[index] || {};
          if (formData.sortOrder) {
            updatedSelectedSortOrders.add(formData.sortOrder);
          }
          return {
            display: formData.display ?? 1,
            title: formData.title || "",
            url: formData.url || "",
            sortOrder:formData.sortOrder?formData.sortOrder:index +1,
            images: (formData.images || []).map((img, imgIndex) => ({
              key: `server-image-${imgIndex}`,
              url: img.url,
              order: imgIndex,
              file: null,
            })),
            updatedAt: formData.updatedAt || "",
            userName: currentRegionData.updatedBy || "",
            errors: { title: '', url:'',display: '', images: '' }
          };
        });

        setForms(newForms);
        setSelectedSortOrders(updatedSelectedSortOrders); 
        setIsLoading(false)
      }
    } catch (err) {
      console.error(err);
      showNotification({
        type: "error",
        message: "Error",
        description: "Failed to load region data"
      });
    } finally {
      setIsLoading(false);
    }
  }

  const handleImageUpload = (event, formIndex) => {
    const files = Array.from(event.target.files);
    
    files.forEach((file, index) => {
      const fileName = file.name.toLowerCase();
      if (!fileName.match(/\.(jpg|jpeg|png|webp|gif)$/)) {
        showNotification({
          type: "error",
          message: "Error",
          description: "Please upload only jpg, jpeg, and png format images",
        });
        return;
      }
      
      if (file.size > 1024 * 1024 * 3) {
        showNotification({
          type: "error",
          message: "Error",
          description: "Please upload file less than 3MB size",
        });
        return;
      }

      new Compressor(file, {
        quality: 0.6,
        success: (compressedResult) => {
          const imageUrl = URL.createObjectURL(compressedResult);
          
          setForms(prevForms => {
            const newForms = [...prevForms];
            const currentForm = { ...newForms[formIndex] };
            currentForm.images = [
              ...currentForm.images,
              {
                key: `image-${Date.now()}-${index}`,
                url: imageUrl,
                order: currentForm.images.length,
                file: compressedResult
              }
            ];
            newForms[formIndex] = currentForm;
            return newForms;
          });
        }
      });
    });
  };

  
  const removeImage = (formIndex, imageIndex) => {
    setForms(prevForms => {
        const newForms = [...prevForms];
        const currentForm = { ...newForms[formIndex] };
        
        // Filter out the image at imageIndex and update order for remaining images
        currentForm.images = currentForm.images
            .filter((_, idx) => idx !== imageIndex)
            .map((img, newIdx) => ({
                ...img,
                order: newIdx,
                key: `server-image-${newIdx}` // Update key if needed
            }));

        newForms[formIndex] = currentForm;
        return newForms;
    });
};
  const handleFormInput = (formIndex, name, value) => {
    setForms(prevForms => {
      const newForms = [...prevForms];
      const currentForm = { ...newForms[formIndex] };
      currentForm[name] = value;
      currentForm.errors = {
        ...currentForm.errors,
        [name]: value === "" ? `Please enter the ${name}` : ""
      };
      newForms[formIndex] = currentForm;
      return newForms;
    });
  };

  const [pageLoading, setPageLoading] = useState(false);
    const [showPageClearConfirm, setShowPageClearConfirm] = useState(false);
  //valid forms  
const validateAllForms = () => {
  let isValid = true;
  const seenSortOrders = new Set();

  const updatedForms = forms.map(form => {
 // Validate form
 const errors = {
  title: form.title === "" ? 'Please enter the Title' : '',
  url: form.url === "" ? 'Please enter the Url' : '',
  display: form.display === "" ? 'Please select Display Option' : '',
  sortOrder: !form.sortOrder ? 'Please select a sort order' : '',
  images: form.images.length === 0 ? 'Please upload at least one image' : ''
};
 // ccheck for duplicate sort orders
 if (form.sortOrder) {
  if (seenSortOrders.has(form.sortOrder)) {
    errors.sortOrder = 'Duplicate sort order found';
    isValid = false;
  } else {
    seenSortOrders.add(form.sortOrder);
  }
}

if (Object.values(errors).some(error => error !== '')) {
  isValid = false;
}

return { ...form, errors };




  });

  setForms(updatedForms);
  return isValid;

}
const submitAllForms = async () => {
  if (!validateAllForms()) {
    showNotification({
      type: "error",
      message: "Validation Error",
      description: "Please check all forms for errors"
    });
    return;
  }
  setPageLoading(true);
try {
  for (let formIndex = 0; formIndex < forms.length; formIndex++) {
    const currentForm = forms[formIndex];
    const finalData = new FormData();
    const regiondata = {
      // region: {
        regionId: regionId || null,
        title: currentForm.title,
        url: currentForm.url,
        display: currentForm.display,
        sortOrder: currentForm.sortOrder,
        images: currentForm.images.map(img => ({
          url: img.url,
          altText: "Uploaded image"
        }))
      // }
    };
    finalData.append('id', props.state);
    finalData.append('modulename', "SpecialOfferCategory");
    finalData.append('formData', JSON.stringify({
      id: props.state,
      region: regiondata,
      formIndex: formIndex
    }));

  // Append images for current form
  currentForm.images.forEach((img, index) => {
    if (img.file) {
      finalData.append(`banner_image_${index}`, img.file);
    }
  });

  console.log(`Submitting form ${formIndex}:`, {
    id: props.state,
    region: regiondata,
    formIndex: formIndex
  });


 // Make the API call for current form
 const response = await AxiosInstance(PORTS.DASHBOARD_SERVICE).post(
  '/addEditRegionLanding',
  finalData,
  { headers: { "content-type": "multipart/form-data" } }
);

if (response.data.status !== 200) {
  throw new Error(`Failed to save form ${formIndex + 1}`);
}





  }

    // After all forms are saved successfully
   
    showNotification({
      type: "success",
      message: "Success",
      description: "All forms updated successfully"
    });
    await getRegionWiseLanding();
  
} catch (error) {
  console.error('Save all forms error:', error);
  showNotification({
    type: "error",
    message: "Error",
    description: error.message || "Failed to save all forms. Please try again."
  });
}finally {
  setPageLoading(false);
}


};
 
const clearAllForms = async () => {
  setShowPageClearConfirm(false);
  setPageLoading(true);

  try {
    console.log("form",forms);
    
    // Check if all forms have empty titles and images
    const allFormsEmpty = forms.every(form => form.title === "" && form.images.length === 0);

    if (allFormsEmpty) {
      showNotification({
        type: "warning",
        message: "No Data",
        description: "All forms are empty. There is nothing to clear."
      });
      setPageLoading(false);
      return; // Exit the function if all forms are empty
    }
    // Clear each form sequentially
    for (let formIndex = 0; formIndex < forms.length; formIndex++) {
      const response = await AxiosInstance(PORTS.DASHBOARD_SERVICE).post(
        '/clearRegionLanding',
        {
          id: props.state,
          regionId: regionId || null,
          formIndex
        }
      );

      if (response.data.status !== 200) {
        throw new Error(`Failed to clear form ${formIndex + 1}`);
      }
    }

    await getRegionWiseLanding();
    showNotification({
      type: "success",
      message: "Success",
      description: "All forms cleared successfully"
    });

  } catch (error) {
    console.error('Clear all forms error:', error);
    showNotification({
      type: "error",
      message: "Error",
      description: error.message || "Failed to clear all forms. Please try again."
    });
  } finally {
    setPageLoading(false);
  }
};
  
  const showNotification = ({ type, message, description }) => {
    notification[type]({
      message: message || "Message",
      description,
      duration: 5,
    });
  };
if(isLoading==true){
  return(
    <LoadingIndicator loadingText='Fetching banner Details'/>
  )
}
  const renderForm = (formIndex) => {
    const currentForm = forms[formIndex];

    return (
      <div key={formIndex} className={`${styles.management_details_contents} mb-5`}>
         <div className={`${styles.banner_citys}`}>
         <h3 className={`${styles.management_header}`}>
          {/* <span> */}
            Banner {formIndex + 1}
            {/* </span> */}
          </h3>
        {currentForm.userName && currentForm.updatedAt && (
          <div className={styles.update_info}>
              <div className={`${styles.banner_citys_update}`}>
            <p>Last updated by: <span>{currentForm.userName}</span></p>
            <p>Last update on: <span>{moment(currentForm.updatedAt).format("DD/MM/YYYY | hh:mm A")}</span></p>
            </div>
          </div>
        )}
         </div>
      
        <div className="mb-4">
          <label className={commonstyles.secondry_title}>
            Title<span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative" }}>*</span>
          </label>
          <InputComponent
            error={currentForm.errors.title}
            className="form-control-lg"
            formControl={{
              placeholder: "Type here",
              name: "title",
              maxLength: 100,
              value: currentForm.title,
              onChange: (e) => handleFormInput(formIndex, "title", e.target.value)
            }}
          />
          <FormErrorText error={currentForm.errors.title} />
        </div>
        <div className="mb-4">
          <label className={commonstyles.secondry_title}>
           Url<span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative" }}>*</span>
          </label>
          <InputComponent
            error={currentForm.errors.title}
            className="form-control-lg"
            formControl={{
              placeholder: "Type here",
              name: "url",
              maxLength: 100,
              value: currentForm.url,
              onChange: (e) => handleFormInput(formIndex, "url", e.target.value)
            }}
          />
          <FormErrorText error={currentForm.errors.url} />
        </div>

        <div className="mb-4">
          <label className={commonstyles.secondry_title}>
            Banner Enable/Disable<span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative" }}>*</span>
          </label>
          <SelectComponent
            error={currentForm.errors.display}
            className="form-control-lg"
            attributes={{
              name: "display",
              value: currentForm.display,
              onChange: (e) => handleFormInput(formIndex, "display", e.target.value)
            }}
          >
            <option value={1}>Enable</option>
            <option value={0}>Disable</option>
          </SelectComponent>
          <FormErrorText error={currentForm.errors.display} />
        </div>
{/* sortorder */}
<div className="mb-4">
            <label className={commonstyles.secondry_title}>
              Sort Order<span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative" }}>*</span>
            </label>
            <SelectComponent
              error={currentForm.errors.sortOrder}
              className="form-control-lg"
              attributes={{
                name: "sortOrder",
                value: currentForm.sortOrder,
                onChange: (e) => handleSortOrderChange(formIndex, e.target.value)
              }}
            >
               {getSortOptions()}
            </SelectComponent>
            <FormErrorText error={currentForm.errors.sortOrder} />
          </div>
        <div className="row">
          <div className="col-lg-6 mb-4">
            <label className={commonstyles.secondry_title}>
              Upload Images<span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative" }}>*</span>
            </label>
            <input
              type="file"
              accept="image/*"
              onChange={(e) => handleImageUpload(e, formIndex)}
              className="form-control"
              disabled={currentForm.images && currentForm.images.length > 0}
            />
              {currentForm.images && currentForm.images.length > 0 ? (
        <small className="text-muted">
            Please remove existing image to upload new ones
        </small>
    )
    :(
      <small className="text-muted">
      Please upload file less than 3MB size
  </small>
    )
    }
            <FormErrorText error={currentForm.errors.images} />
          </div>

          <div className="col-lg-6 mb-4">
            <label className={commonstyles.secondry_title}>Preview Images</label>
            <div className={commonstyles.banner_img_box_outline}>
              {currentForm.images.map((img, index) => (
                <div key={img.key} className={commonstyles.banner_img_preview}>
                  <img
                    src={img.url}
                    alt={`preview-${index}`}
                    className={commonstyles.previewImgs}
                  />
                  <img
                    src={bannerimgdelete}
                    alt="delete"
                    className={commonstyles.previewImgsdeleteicon}
                    onClick={() => removeImage(formIndex, index)}
                  />
                  <div className={styles.imgpreview_index}>{index + 1}</div>
                </div>
              ))}
            </div>
          </div>
        </div>

       

      
      </div>
    );
  };

  return (
    <div className={styles.management_contents}>
      <div className={styles.management_header}>
        <h1>
          <img
            src={BlackLeftArrow}
            alt=""
            className="cursor-pointer"
            onClick={() => history.push("/Content")}
          />
          <span>{props.moduleName}</span>
        </h1>
      </div>

      <div className={`${styles.management_header} ${styles.banner_city_list}`}>
        <div className={styles.banner_citys}>
          <SelectComponent
            className="form-control-md"
            attributes={{
              name: "regionId",
              value: regionId,
              onChange: (e) => setRegionId(e.target.value)
            }}
          >
            <option value="">All Region</option>
            {Array.isArray(props.filterRegions) &&
              props.filterRegions?.map((region) => (
                <option key={region._id} value={region._id}>
                  {region.name}
                </option>
              ))}
          </SelectComponent>
        </div>
        <ButtonComponent
            className="rnd mr-3 outline_cancel_btn"
            attributes={{
              onClick: () => setShowPageClearConfirm(true),
              disabled: pageLoading
            }}
          >
            Clear All
          </ButtonComponent>
          <ButtonComponent
            className="rnd mr-3"
            attributes={{
              onClick: submitAllForms,
              disabled: pageLoading
            }}
          >
            {pageLoading ? "Saving All..." : "Save All"}
          </ButtonComponent>
        <ButtonComponent
          className="rnd outline_cancel_btn"
          attributes={{
            onClick: () => history.push('/Content')
          }}
        >
          Cancel
        </ButtonComponent>
      </div>

      {/* Render all 5 forms */}
      {[0, 1,2,3].map(formIndex => renderForm(formIndex))}
      <ConfirmationBox
        modal={{
          show: showPageClearConfirm,
          size: "md",
          centered: true,
        }}
        handleCancel={() => setShowPageClearConfirm(false)}
        handleConfirm={clearAllForms}
        title="Confirm the action"
        confirmText="Clear All"
      >
        <div className="container-fluid">
          <div className="row">
            <p className="theme-txt-detail">
              Are you sure you want to clear all forms? This action cannot be undone.
            </p>
          </div>
        </div>
      </ConfirmationBox>
   
    </div>
  );
};

export default SpecialOfferCategoryBanners