import React, { useState, useEffect } from "react";
import styles from "../../../styles/PaymentNew.module.css";
import BlackLeftArrow from "../../../assets/images/black_left_arrow.svg";
import { useHistory } from "react-router";
import { notification, Select, Checkbox } from "antd";
import ButtonComponent from "../../modules/ReusableComponent/ButtonComponent";
import InputComponent from "../../modules/ReusableComponent/InputComponent";
import FormErrorText from "../../modules/ProductManagement/FormErrorText";
import LoadingIndicator from "../../modules/ReusableComponent/LoadingIndicator";
import commonstyles from "../../../styles/Management_Common.module.css";
import validationRules from "../../../utils/validationRules";
import Plus from "../../../assets/images/plus_lynch_icon.svg";
import exclusionIcon from "../../../assets/images/exclusion.svg";
import bannerImg from "../../../assets/images/banner1.svg";
import bannerImg2 from "../../../assets/images/banner2.svg";
import bannerImg3 from "../../../assets/images/banner3.svg";
import listingBanner from "../../../assets/images/listingBanner.svg";
import listingBanner1 from "../../../assets/images/product_list_1.svg";
import listingBanner2 from "../../../assets/images/product_list_2.svg";

import modalBanner from "../../../assets/images/modalBanner.svg";
import bannerimgdelete from "../../../assets/images/Iconly-Bulk-Close Square.svg";
import ProductCategoryTreeSelectComponent from "../../modules/ProductManagement/ProductCategoryTreeSelectComponent";

export default function CommonBanner(props) {
   
  function showNotification({ type, message, description }) {
    let options = {
      message: message || "Message",
      description: description,
      duration: 5,
    };
    notification[type](options);
  }
    const [draggedImage, setDraggedImage] = useState(null);

    const onDragStart = (e, index) => {
        setDraggedImage(index);
    };

    const onDrop = (e, dropIndex) => {
        const updatedImages = [...props.item.images];
        const draggedImg = updatedImages[draggedImage];

       
        updatedImages.splice(draggedImage, 1);
        updatedImages.splice(dropIndex, 0, draggedImg);

     
        props.updateImages(updatedImages, props.bannerName, props.indx);

 
        setDraggedImage(null);
    };

    return (
        <div>
            <div className="row">
                <div className="col-lg-6 mb-4">
                    <label className={commonstyles.secondry_title}>
                        Name{" "}
                        <span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative" }}>
                            *
                        </span>
                    </label>

                    <InputComponent
                        error={props.mainLeftBannerErrors.name}
                        className="form-control-lg mb-3"
                        formControl={{
                            placeholder: "Type banner name",
                            name: "name",
                            value: props?.item?.name,
                            onChange: (e) => props.handleFormInput(e, props?.indx, props.bannerName),
                        }}
                    />
                    <FormErrorText error={props.mainLeftBannerErrors.name} />
                </div>
                <div className="col-lg-6 mb-4">
                    <label className={commonstyles.secondry_title}>
                        Slug
                    </label>
                    <InputComponent
                        error={props.mainLeftBannerErrors.name}
                        className="form-control-lg mb-3"
                        formControl={{
                            placeholder: "Type slug",
                            name: "slug",
                            value: props?.item?.slug,
                            onChange: (e) => props.handleFormInput(e, props?.indx, props.bannerName),
                        }}
                    />
                    <FormErrorText error={props.mainLeftBannerErrors.slug} />
                </div>
                <div className="col-lg-6 mb-4 banner_region_select">
                    <label className={commonstyles.secondry_title}>
                        Warehouse{" "}
                        <span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative" }}>
                            *
                        </span>
                    </label>
                    <Select
                        mode="multiple"
                        showSearch={false}
                        dropdownMatchSelectWidth={false}
                        filterOption={false}
                        placeholder="Search regions"
                        // loading={props.isFetchingTemp}`
                        bordered={false}
                        // 
                           // remove maxTagCount and maxTagPlaceholder to show all tags,warehouse option selected UI
        maxTagCount={null}
        // Style configurations for showing all options
        style={{
          width: '100%',
          height: 'auto',
          minHeight: '38px',
          // Ensure content wraps properly
          whiteSpace: 'normal',
          lineHeight: '1.5'
        }}
        //  tag display
        maxTagTextLength={null}
        // Custom class for the selector
        dropdownClassName="warehouse-select-dropdown"
        //  multi-select options
        tokenSeparators={[',']}
     

                        // 
                        name="regionId"
                        value={props?.item.regionId}
                        disabled={props?.regions?.length === 1}
                        onChange={(value) => props.searchSelectOnChange(value, 'regionId', props?.indx, props.bannerName)}
                        className={`${commonstyles.form_control} form-control form-control-lg p-1`}
                    >
                        <option value="All">All Warehouse</option>
                        {Array.isArray(props.remainingRegions)
                            ? props.remainingRegions.map((region) => (
                                <option key={region.name} value={region.name}>
                                    {region.name}
                                </option>
                            ))
                            : null}
                    </Select>
                    <FormErrorText error={props.mainLeftBannerErrors.regionId} />
                </div>
                <div className="col-lg-6 mb-4">
                    <label className={commonstyles.secondry_title}>
                        Discount {props.item?.type == 9 && props?.page == 2 && "(Not Applicable)"}

                        <span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative" }}>
                            *
                        </span>
                    </label>

                    <InputComponent
                        error={props.mainLeftBannerErrors.name}
                        className="form-control-lg mb-3"
                        formControl={{
                            disabled: props.item?.type == 9 && props?.page == 2 ? true : false,
                            placeholder: "Type discount",
                            name: "discount",
                            value: props?.item?.discount,
                            onChange: (e) => props.handleFormInput(e, props?.indx, props.bannerName),
                        }}
                    />
                    <FormErrorText error={props.mainLeftBannerErrors.discount} />
                </div>
                <div className="col-lg-6 mb-4">
                    <label className={commonstyles.secondry_title}>
                        Categories
                    </label>
                    <ProductCategoryTreeSelectComponent
                        value={props?.item.categoryId}
                        category={props.categoryNames}
                        handleChange={(v) => props.searchSelectOnChange(v, 'categoryId', props?.indx, props.bannerName)}
                    />
                </div>
                <div className="col-lg-6 mb-4">
                    <label className={`${commonstyles.secondry_title} ${commonstyles.banner_layout}`}>
                        Banner Layout
                        <img alt="" src={((props.bannerName === "leftRightTopBanner" && props?.page === 1)) ? bannerImg2 : ((props.bannerName === "madeForBanner" && props?.page === 1) ? bannerImg3 : (props?.page === 2 && props.item?.type == 9) ? listingBanner1 : ((props?.page === 2) ? listingBanner2 : ((props?.page === 3) ? modalBanner : bannerImg)))} />
                    </label>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-6 mb-4">
                    <div className="d-flex align-items-right justify-content-between">
                        <div className={commonstyles.abandoned_email}>
                            <div className={`${commonstyles.abandoned_email_checkbox}`}>
                                <Checkbox name="isActive" onChange={(e) => props.handleCheckboxChange(e, props?.indx, props.bannerName)}>Image compressor</Checkbox>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-6 mb-4">
                    <div className="d-flex align-items-right justify-content-between">
                        <label className={commonstyles.secondry_title}>
                            Add Image{" "}
                            <span className="text-danger" style={{ fontSize:"20px",bottom:-8,position:"relative" }}>
                                *
                            </span>
                        </label>
                        {/* <div className={styles.banner_add_image}>
                            <p> Add Image</p>
                        </div> */}
                    </div>
                    <div className={`${commonstyles.banner_img_box_outline}`}>
                        <div className={`${commonstyles.management_details_contents} mb-2 mt-2`}>
                            <div
                                className={`${commonstyles.management_details_form} ${commonstyles.add_image_card
                                    } d-flex align-items-center justify-content-center`}
                            >
                                {/* <div className={`${commonstyles.upload_img} text-center`}>
                            <img src={exclusionIcon} alt="" />
                            <div>
                            <label
                                htmlFor="imageInput"
                                className={`${commonstyles.btn} px-2 cursor-pointer`}
                            >
                                Add image
                            </label>
                            <input
                                name="image"
                                className="d-none"
                                type="file"
                                accept="image/*"
                                id="imageInput"
                            />
                            </div>
                            <p>or drop a file to Upload</p>
                        </div> */}
                                <input
                                    name="image"
                                    // className="d-none"
                                    type="file"
                                    accept="image/*"
                                    id="imageInput"
                                    value=""
                                    onChange={(e) => props.handleImageInput(e, props?.indx, props.bannerName)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 mb-4">
                    <label className={commonstyles.secondry_title}>
                        Preview order
                    </label>
                    {props?.bannerName == "mainLeftBanner" ? (<div className={`${commonstyles.banner_img_box_outline}`}>
                        {props?.item?.images?.length > 0 &&
                            props?.item?.images?.map((imgs, index) => (
                                <div
                                    className={`${commonstyles.banner_img_preview}`}
                                    key={index}
                                    draggable
                                    onDragStart={(e) => onDragStart(e, index)}

                                    onDragOver={(e) => e.preventDefault()}

                                    onDrop={(e) => onDrop(e, index)}

                                >
                                    <img
                                        className={commonstyles.previewImgs}
                                        src={imgs?.name ? URL.createObjectURL(imgs) : imgs}
                                        alt={imgs?.name}
                                    />
                                    <img
                                        onClick={() =>
                                            props.removeImages(imgs, index, props?.indx, props.bannerName)
                                        }
                                        className={commonstyles.previewImgsdeleteicon}
                                        src={bannerimgdelete}
                                        alt="deletee"
                                    />
                                </div>
                            ))}
                    </div>) : (<div className={`${commonstyles.banner_img_box_outline} `}>
                        {props?.item?.images?.length > 0 && props?.item?.images?.map((imgs, index) => (
                            <div className={`${commonstyles.banner_img_preview}`}     onDragStart={() => {
                                showNotification({
                                  type: "info",
                                  message: "Single Banner",
                                  description: "You cannot sort or drag this banner as only one is available.",
                                });
                              }}>
                                <img
                                    class={commonstyles.previewImgs}
                                    key={index}
                                    src={imgs?.name ? URL.createObjectURL(imgs) : imgs}
                                    alt={imgs?.name}
                                />
                                <img onClick={() => props.removeImages(imgs, index, props?.indx, props.bannerName)} class={commonstyles.previewImgsdeleteicon} src={bannerimgdelete} alt="delete" />
                            </div>
                        ))}
                    </div>)}



                    {/* <div className={`${commonstyles.banner_img_box_outline} `}>
                        {props?.item?.images?.length > 0 && props?.item?.images?.map((imgs, index) => (
                            <div className={`${commonstyles.banner_img_preview}`}>
                                <img
                                    class={commonstyles.previewImgs}
                                    key={index}
                                    src={imgs?.name ? URL.createObjectURL(imgs) : imgs}
                                    alt={imgs?.name}
                                />
                                <img onClick={() => props.removeImages(imgs, index, props?.indx, props.bannerName)} class={commonstyles.previewImgsdeleteicon} src={bannerimgdelete} alt="delete" />
                            </div>
                        ))}
                    </div> */}
                </div>
            </div>
            <div className="d-flex align-items-right justify-content-end">
                {!props.regionId && props.indx > 0 && <div
                    className={styles.banner_delete_btn}
                    onClick={() => props.removeBanners(props?.indx, props.bannerName)}
                >
                    <p>Delete</p>
                </div>}
                {!props.regionId && <div
                    className={styles.title_text_value}
                    onClick={() => props.addBanners(props.bannerName, props.item?.type)}
                >
                    <img src={Plus} alt="" />
                    <span className="theme-btn-link">Add Banner for New Warehouse</span>

                </div>}

              
            </div>
        </div>
    );
}